import { graphql, Link, withPrefix } from "gatsby";
import { kebabCase } from "lodash";
import Layout from "../../components/layout";
import MainContent from "../../components/maincontent";
import PageBanner from "../../components/banner/banner";
import PropTypes from "prop-types";
import React from "react";
import Seo from "../../components/seo";

const BlogIndexPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout>
      <Seo
        title="Blog"
        description="Communicating the truths of God's Word."
        lang="en"
        meta={[
          {
            content: "God, Jesus, Clear, Gospel, Truth, Heaven, Salvation, Saved, Grace, Free, How, Mid-Acts, Romans",
            name: "keywords"
          }
        ]}
      />

      <PageBanner
        hasImage={false}
        hasOverlay={false}
      />

      <MainContent
        hasArticle={true}
      >
        <div className='article-header'>
          <h1>Blog</h1>
        </div>

        <div className="card">
          <div className="grid article-width">
            <section>
              {/* For each post... make an entry */}
              {posts.map(({ node: post }) => {
                const { frontmatter } = post;
                return (
                  <div key={`key-${frontmatter.title}`}>
                    <header className="blog-list--header">
                      <Link className="blog-list--heading" to={frontmatter.path}>
                        <div
                          className="flex flex--standard blog-preview"
                        >
                          <img
                            src={`${withPrefix(frontmatter.image)}`}
                            alt="post-image"
                          />
                        </div>
                        <h2
                          className="blog-list--header"
                        >
                          <span>{frontmatter.title}</span>
                        </h2>
                      </Link>
                    </header>
                    <div className="blog-date">
                      {frontmatter.date}
                    </div>
                    <span>
                      {frontmatter.description}
                      &nbsp;
                      <Link
                        className="link link--blue"
                        to={frontmatter.path}
                      >
                        Read More...
                      </Link>
                    </span>
                    <ul className="blog-list--post-tags blog-list--spacing" style={{ display: "block" }}>
                      {frontmatter.tags.map((tag) => {
                        return (
                          <li key={`key-${tag}`}>
                            <Link to={`/blog/tags/${kebabCase(tag)}/`}>{tag}</Link>
                          </li>
                        );
                      })}
                    </ul>
                    <hr className="blog-hr" />
                  </div>
                );
              })}
            </section>
          </div>
        </div>

      </MainContent>

    </Layout>
  );
};

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            description
            tags
            image
          }
        }
      }
    }
  }
`;

export default BlogIndexPage;

BlogIndexPage.propTypes = {
  data: PropTypes.node.isRequired
};
